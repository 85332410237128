<template>
  <div
    fluid
    class="pa-0"
  >
    <Loader v-if="loaderState" />
    <v-card
      v-if="!loaderState"
      class="pt-6 px-6 mt-3 plans-height"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <div class="">
        <div class="d-flex align-center">
          <button
            plain
            class="btn-nav-back font-inter"
            @click="handleBackClick($event)"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <span class="d-flex-inline justify-center align-center ma-0 blue--text">
              {{ backTitle }}
            </span>
          </button>
          <button
            class="btn-close ml-auto"
            @click="handleBackClick($event)"
          >
            <v-icon>mdi-close</v-icon>
          </button>
        </div>

        <h2 class="font-inter text-start">
          {{ $t('plans.duplicate.rerun.title') }}
        </h2>
        <div class="mt-4 d-flex">
          <v-chip
            class="font-inter font-weight-bold px-4"
            width="200px"
            label
            :class="{ 'blue--text': tableFilter === 'all' }"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            @click="changeFilter('all')"
          >
            {{ $t('all') }} <span class="ml-2">{{ runs.length }}</span>
          </v-chip>
          <v-chip
            class="font-inter font-weight-bold px-4 ml-2"
            label
            width="200px"
            :class="{ 'blue--text': tableFilter === 'selected' }"
            :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
            @click="changeFilter('selected')"
          >
            {{ $t('selected') }} <span class="ml-2">{{ selectedTestRunsLength }}</span>
          </v-chip>
        </div>
      </div>

      <div class="d-flex justify-space-between">
        <div class="left-width">
          <div class="sticky-on-scroll mt-5 pa-4 bg-gray-theme rounded-lg
          d-flex flex-column justify-space-between
          ">
            <div
                class="d-flex flex-column"
              >
                <v-text-field
                  :placeholder="$t('search')"
                  background-color="#FFFFFF"
                  class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0 mb-3 flex-inherit"
                  height="38"
                  dense
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <SearchIcon />
                  </template>
                </v-text-field>

                <v-btn
                  text
                  color="#061AAE"
                  class="text-capitalize rounded-lg bg-gray-theme text-theme-secondary justify-start mb-3"
                  width="100%"
                >
                  {{ $t('testruns.all') }}
                </v-btn>

                <v-btn
                  text
                  color="#061AAE"
                  class="text-capitalize rounded-lg bg-gray-theme text-theme-secondary justify-start mb-3"
                  width="100%"
                >
                  <div class="d-flex align-center">
                    <div class="mr-2">
                      <UnlinkedIcon />
                    </div>
                    <span>{{ $t('testruns.unlinked') }} 0</span>
                  </div>
                </v-btn>

                <v-btn
                  text
                  color="#061AAE"
                  class="text-capitalize rounded-lg bg-theme-primary-light text-theme-secondary justify-start mb-2"
                  width="100%"
                  @click="onToPlanExpanded"
                >
                  <div class="d-flex justify-space-between w-full">
                    <div class="d-flex align-center">
                      <div class="mt-1 mr-2">
                        <LinkedIcon />
                      </div>
                      <span>{{ $t('testruns.toPlans') }} {{ getActivePlansCount }}</span>
                    </div>
                    <div>
                      <v-icon>
                        {{ isToPlanExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }} 
                      </v-icon>
                    </div>
                  </div>
                </v-btn>

                <div
                  v-if="isToPlanExpanded"
                  class="plan-list-wrapper"
                >
                  <div
                    v-for="(plan, index) in getActivePlans"
                    :key="index"
                  >
                    <v-tooltip
                      top
                      left
                      max-width="200px"
                      :disabled="plan.name.length < 15"
                      content-class="tooltip-theme"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          color="#667085"
                          class="text-capitalize btn-full font-weight-regular rounded-lg justify-start mb-2"
                          width="100%"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div class="d-flex justify-between align-center w-full">
                            <div class="text-truncate">
                              {{ plan.name }}
                            </div>
                            <div>(0)</div>
                          </div>
                        </v-btn>
                      </template>
                      <span>{{ plan.name }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <div
                  v-else
                  class="plan-list-wrapper"
                />
            </div>
            <div
              class="sticky-scroll"
            >
              <div class="text-left">
                <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                  {{ $t("createQuickPlan") }} 
                </v-label>
                <v-text-field
                  :placeholder="$t('enterName')"
                  height="38"
                  background-color="#FFFFFF"
                  class="field-theme mt-0 pt-1"
                />
              </div>
              <v-btn
                type="submit"
                block
                color="primary"
                :depressed="true"
                class="btn-theme"
                width="100%"
                height="40"
              >
                {{ $t("create") }}
              </v-btn>
            </div>
          </div>
        </div>

        <div class="right-width">
          <div class="pl-6">
            <div  
              class="d-flex flex-column align-center"
              v-if="runs.length == 0"
            >
              <ActiveEmptyState
                  :imageSrc="require('@/assets/png/auth-banner.png')"
                  :title="$t('plans.beginTestNewTitle')"
                  :buttonText="$t('testruns.create_testrun.title')"
                  buttonColor="primary"
                  @button-click="openCreateTestRun"
                >
                  <template slot="description">
                    <div class="text-center">
                      <div class="mb-0 mt-3">{{ $t('plans.addRuns.description.part1') }}</div>
                      <div class="mb-0">{{ $t('plans.addRuns.description.part2') }}</div>
                    </div>
                  </template>
                </ActiveEmptyState>
            </div>
            <div v-else
            class="mt-5"
            >
            <div class="d-flex flex-row justify-space-between align-center">
              <div class="d-flex flex-row justify-start align-center">
                <search-field
                  v-model="searchFilter"
                  class="search-input mr-2"
                  :placeholder="$t('searchByName')"
                />
                <RunFilter
                  :configurations="[]" 
                  :tags="tags"
                  :milestones="activeMilestones"
                  @applyFilters="applyFilters"
                />
              </div>
              <SettingsMenu 
                table-type="addTestRun" 
              />
            </div>
            <test-run-list
              class="test-runs-list"
              :data="runs"
              :tab="tableFilter"
              :headers="isTableFilterAll ? filteredHeadersAll : filteredHeadersSelected"
              :selected-runs="selectedRunsLocal"
              @selectTestRun="selectTestRun"
              @addConfiguration="handleAddConfiguration"
            />
            <div class="action-btn-wrapper pa-3" v-if="selectedTestRunsLength">
              <slot name="action" />
            </div>
            </div>
          </div>
        </div>

      </div>
    </v-card>
    <DuplicateAndApplyConfigDialog
      :value="duplicateAndApplyConfigDialog && !loaderState"
      :configurations="configurationSorted"
      :button-loading="buttonLoading"
      :button-loading-items="buttonLoadingItems"
      :is-menu-visible="buttonLoading"
      @close="onCloseDuplicateAndApplyConfigDialog"
      @addConfiguration="addConfiguration" 
      @addConfigurationItems="addConfigurationItems"
    />
  </div>
</template>
<script>
import TestRunList from '@/views/Tests/Plans/Components/TestRunList';
import DuplicateAndApplyConfigDialog from '@/components/TestRuns/DuplicateAndApplyConfigDialog.vue';
import RunFilter from '@/components/TestRuns/RunFilter.vue';
import {
  runStateMap,
} from '@/constants/grid.js';
import { createNamespacedHelpers, mapGetters, mapState, mapActions } from 'vuex';
import makePlanService from '@/services/api/plan';
import makeConfigurationService from '@/services/api/configuration' 
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { formatDate } from '@/utils/util';
import makeTagsService from '@/services/api/tag';
import makeMilestonesService from '@/services/api/milestone'
import Loader from '@/components/base/Loader'
import handleLoading from '@/mixins/loader.js'
import SearchField from '@/components/Form/SearchField'
import ActiveEmptyState from '@/components/base/ActiveEmptyState.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
import UnlinkedIcon from '@/assets/svg/unlinked.svg';
import LinkedIcon from '@/assets/svg/linked.svg';

const { mapActions: mapRunActions } = createNamespacedHelpers('run');
const { mapMutations: mapPlanMutations } = createNamespacedHelpers('plan');

let configurationService;

export default {
  components: {
    TestRunList,
    RunFilter,
    DuplicateAndApplyConfigDialog,
    SettingsMenu,
    Loader,
    SearchField,
    ActiveEmptyState,
    SearchIcon,
    UnlinkedIcon,
    LinkedIcon,
  },
  mixins:[handleLoading],
  data() {
    return {
      filter: 'ongoing',
      isColumnFilter: false,
      selectedRunsLocal: this.value,
      tableFilter: 'all',
      searchFilter: '',
      appliedFilters: null,
      headers: [],
      tags: [],
      milestones: [],
      menuOpen: false,
      isOpenAddConfiguration: false,
      isToPlanExpanded: false,
      duplicateAndApplyConfigDialog: false,
      buttonLoading: false,
      buttonLoadingItems: false,
      configurations: [],
      headersTableAll: [],
      headersTableSelected: [],
    };
  },
  props: {
    value: {
      type: Array,
    },
    backTitle: {
      type: String,
    },
  },
  emits: ['update:value'],
  computed: {
    ...mapState('run', ['items']),
    ...mapState('user', ['currentAccount']),
    ...mapState('plan', ['testPlan', 'selectedRuns']),
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    configurationSorted() {
      return [...this.configurations].sort((a, b) => a.name.localeCompare(b.name));
    },
    runs() {
      const finalRuns = this.items?.map((item) => {
        if(item.archivedAt)
          return false;
          
        const createdFormat = new Date(item.createdAt);
        const createdAt = formatDate(createdFormat, 'yyyy-MM-dd');
        const runTags = item.testMilestones?.map(item => item.uid);
        if(this.searchFilter.length && !this.matchesFilter(item))
          return false;
        if(this.appliedFilters){
          if(item.testcases < this.appliedFilters?.testCasesRange[0] || item.testcases > this.appliedFilters?.testCasesRange[1])
            return false
          if(item.percentage < this.appliedFilters?.progressRange[0] || item.percentage > this.appliedFilters?.progressRange[1])
            return false
          if(this.appliedFilters.panelPriority && !this.appliedFilters.panelPriority.includes(item.priority))
            return false
          if(this.appliedFilters.panelStatus && !this.appliedFilters.panelStatus.includes(item.status))
            return false
          if(this.appliedFilters.dateRange && (item.createdAt < this.appliedFilters?.dateRange.start || item.createdAt > this.appliedFilters?.dateRange.end))
            return false
          if(this.appliedFilters.panelMilestone){
            let milestoneExists = false;
            for(const milestone of this.appliedFilters.panelMilestone){
              if(runTags.includes(milestone))
                milestoneExists = true;
            }
            if(!milestoneExists)
              return false
          }      
          if(this.appliedFilters.panelTag){
            let tagExists = false;
            for(const tag of this.appliedFilters.panelTag){
              if(item.customFields.tags?.includes(tag))
                tagExists = true;
            }
            if(!tagExists)
              return false
          }
        }

        return {
          ...item,
          processedStatus: this.staticValues(item, runStateMap, 'New'),
          createdAt: createdAt
        }
      }).filter(Boolean);
      return finalRuns
    },
    selectedTestRunsLength() {
      return this.selectedRunsLocal.length
    },
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      if (filtered.length < this.headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
    getActivePlans() {
      return this.plans?.filter(plan => plan.archivedAt == null);
    },
    getActivePlansCount() {
      return this.plans?.filter(plan => plan.archivedAt == null).length;
    },
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
    isTableFilterAll() {
      return this.tableFilter == 'all';
    },
    filteredHeadersAll() {
      return this.headersTableAll?.filter((header) => header.checked);
    },
    filteredHeadersSelected() {
      return this.headersTableSelected?.filter((header) => header.checked);
    },
  },
  async created() {
    let handle = this.currentAccount.handle;
    let projectKey = this.$route.params.key;
    if(!this.dynamicHeaders.addTestRun) {
      this.initializeHeaders({ type: 'addTestRun' });
    }
    this.headersTableAll = this.dynamicHeaders.addTestRun;

    if(!this.dynamicHeaders.addTestRunSelected) {
      this.initializeHeaders({ type: 'addTestRunSelected' });
    }
    this.headersTableSelected = this.dynamicHeaders.addTestRunSelected;
    configurationService = makeConfigurationService(this.$api);
    await this.init([this.getProjectRun(handle, projectKey)]);
    
  },
  mounted(){
    let handle = this.currentAccount.handle;
    this.getAllTags(handle, 'runs');
    this.getMilestones();
    this.getTestPlans();
    this.getConfigurations();
  },  
  methods: {
    ...mapPlanMutations(['UPDATE_SELECTED_RUNS']),
    ...mapRunActions(['getAllTestRuns']),
    ...mapActions("headers", ['initializeHeaders']),
    async getProjectRun(handle, projectKey) {
      await this.getAllTestRuns({ handle: handle, projectKey: projectKey, perPage: 99999, currentPage: 0 });
    },
    handleAddConfiguration() {
      this.duplicateAndApplyConfigDialog = true;
    },
    onToPlanExpanded(){
      this.isToPlanExpanded = !this.isToPlanExpanded
    },
    async addConfigurationItems(item) {
      this.buttonLoadingItems = true;
      try {
        await this.updateConfiguration(item.uid, item.name, item.items, item.description);
        await this.getConfigurations();
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
      } finally {
        this.buttonLoadingItems = false;
      }
    },
    async addConfiguration(configuration) {
      this.buttonLoading = true;
      try {
        await this.createConfigurations(configuration.newConfigurationName, configuration.options, configuration.description);
        await this.getConfigurations();
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
      } finally {
        this.buttonLoading = false;
      }
    },
    async getTestPlans() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);
      try {
        const response = await testPlanService.getAllTestPlans(handle, projectKey, 1000, 0);
        this.plans = response.data?.plans;
        return response.data?.plans;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test plans' });
        return [];
      }
    },
    async getConfigurations() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.getConfigurations(handle, projectKey, 1000, 0);
        this.configurations = response.data?.configurations;
        return response.data?.configurations;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async updateConfiguration(uid, name, options, description) {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        
        await configurationService.updateConfiguration(handle, projectKey, uid, {
          name: name,
          options: options,
          description: description,
        });

      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async createConfigurations(name, options, description) {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.createConfigurations(handle, projectKey, {
          name: name,
          options: options,
          description: description,
        });
        return response.data;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    async deleteConfigurations(id) {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const configurationService = makeConfigurationService(this.$api);
      try {
        const response = await configurationService.deleteConfiguration(handle, projectKey, id);
        return response.data;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'configurations' });
        return [];
      }
    },
    onCloseDuplicateAndApplyConfigDialog() {
      this.duplicateAndApplyConfigDialog = false;
    },
    handleAddTestRuns() {
      this.$router.push({
        name: 'TestRunCreate',
        params: {
        handle: this.$route.params.handle,
        key: this.$route.params.key
        },
        query: {
          activeAddTestPlan: 'true'
        },
      });
    },
    handleColumnReset() {
      this.headers = this.headers?.map((header) => {
        header.isSelected = true;
        return header;
      })
    },
    handleBackClick(event) {
      event.preventDefault();
      this.$emit('back');
    },
    changeFilter(filter) {
      this.tableFilter = filter;
      this.$emit('onFilterChange', filter);
    },
    selectTestRun(runs) {
      this.selectedRunsLocal = runs
      this.$emit('input', this.selectedRunsLocal);
    },
    applyFilters(filters) {
      this.appliedFilters = filters;      
    },
    async getAllTags(handle, entityType) {
      const tagService = makeTagsService(this.$api);

      await tagService.getTags(
        handle,
        entityType
      ).then(response => {
        this.tags = response.data;
      })
    },
    async getMilestones(){
      const milestoneService = makeMilestonesService(this.$api);
      await milestoneService.getMilestones(
        this.$route.params.handle,
        this.$route.params.key
      ).then(response => {
        this.milestones = response.data.milestones;
      })
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      const nameMatch = item.name?.toLowerCase().includes(lowerCaseFilter);
      const sourceMatch = item.source?.toLowerCase().includes(lowerCaseFilter);

      return (nameMatch || sourceMatch) ?? false;
    },
    openCreateTestRun() {
      this.$router.push({
        name: 'TestRunCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
        query: {
          page: 'TestPlanCreate'
        }
      });
    }
  },
};
</script>
  
<style scoped>

.left-width {
    width: 12vw;
    min-width: 212px;
}
.right-width {
    width: 88vw;
}

.btn-nav-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3 !important;
  text-transform: none;
  opacity: 1;
  display: flex;
  align-items: center;
}

.plan-list-wrapper {
  height: calc(100vh - 43rem);
  overflow: auto;
  scrollbar-width: thin;
}
.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  min-height: calc(100vh - 24px - 20rem);
  margin-bottom: 24px;
}
.plans-height {
  min-height: calc(100vh - 24px - 6.5rem);
  height: 100%;
}
.action-btn-wrapper {
  position: sticky;
  bottom: 0;
  background-color: white;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  z-index: 8;
}
</style>
